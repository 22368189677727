import React, {
    useState, useRef
} from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import _Input from './../Input';
import _Textarea from './../Textarea';
import _Button from './../Button';
import _Loader from './../Loader';

const Input = styled(_Input)`
    flex: 1;
    margin: 10px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px;
`;

const Label = styled.label`
    font-size: 16px;
    color: ${({ theme }) => theme.colors.mainBlue.hex};
    margin-bottom: 10px;
    white-space: pre-wrap;
`;

const InputHidden = styled(_Input)``;

const FileName = styled.span`
  color: ${({ theme }) => theme.colors.mainBlue.hex};
  font-size: 16px;
`;

const Button = styled(_Button)`
    margin-left: auto;
    cursor: pointer;
    min-width: 160px;
    margin-right: auto;
`;

const Loader = styled(_Loader)`
    margin-left: auto;
    margin-right: auto;
    height: 30px;
    width: 30px;
`;

const ButtonFile = styled(_Button)`
    cursor: pointer;
    margin: 0px 0px 10px;
    max-width: 200px;
`;

const Textarea = styled(_Textarea)`
    flex: 1;
    margin: 10px;
`;

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  padding: 20px 0px 20px 20px;
  width: 60%;
  justify-content: space-between;

  ${({ theme }) => theme.media.littleScreen`
    width: 70%;
    padding: 20px 0px;
  `}

  ${({ theme }) => theme.media.tablet`
    width: 100%;
    padding: 20px 0px;
  `}
`;

const Row = styled.div`
    display: flex;

    ${({ center }) => center ? 'justify-content: center;' : ''}

    ${({ theme }) => theme.media.mobile`
        flex-direction: column;
    `}
`;

const Message = styled.span`
  font-size: 16px;
  transition: opacity 0.250s 0.250s ease, color 0s -500ms;

  color: ${({
        theme, type
    }) => type === 'ok' ? theme.colors.valid.hex : theme.colors.error.hex};

  opacity: ${({ visible }) => visible ? 1 : 0};
  margin-left: auto;
  margin-right: auto;
`;

const acceptedFormats = ['pdf'];
// MB
const MAX_FILE_SIZE = 0.8;

const encode = (data) => {
    const formData = new FormData();
    Object.keys(data).forEach((k) => {
        formData.append(k, data[k]);
    });
    return formData;
};

const ContactForm = () => {
    const { t } = useTranslation();

    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [phone, setPhone] = useState('');
    const [file, setFile] = useState({});
    const [job, setJob] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [returnMessage, setReturnMessage] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const nameRef = useRef();
    const surnameRef = useRef();
    const emailRef = useRef();
    const messageRef = useRef();
    const fileRef = useRef();

    const validEmail = (emailCheck) => {
    // eslint-disable-next-line no-useless-escape
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(emailCheck).toLowerCase());
    };

    const resetForm = () => {
        setName('');
        setSurname('');
        setJob('');
        setPhone('');
        setEmail('');
        setMessage('');
        setFile({});
    };

    const handleButtonFileClick = () => {
        fileRef.current.inputRef.click();
    };

    const handleSetFile = (files) => {
        const fileUpload = files[0];
        const extension = fileUpload.name.split('.').pop();

        if (!acceptedFormats.includes(extension)) {
            fileRef.current.onError('error_file_format');
        } else if (fileUpload.size / 1024 / 1024 > MAX_FILE_SIZE) {
            fileRef.current.onError('error_file_size');
        } else {
            setFile(fileUpload);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let shouldSend = true;

        if (!name) {
            nameRef.current.onError('error_cannot_be_empty');
            shouldSend = false;
        }
        if (!surname) {
            surnameRef.current.onError('error_cannot_be_empty');
            shouldSend = false;
        }
        if (!email) {
            emailRef.current.onError('error_cannot_be_empty');
            shouldSend = false;
        } else if (!validEmail(email)) {
            emailRef.current.onError('error_format');
        }
        if (!message) {
            messageRef.current.onError('error_cannot_be_empty');
            shouldSend = false;
        }

        if (shouldSend) {
            setIsLoading(true);
            fetch('/', {
                method: 'POST',
                body: encode({
                    'form-name': 'career',
                    name,
                    surname,
                    job,
                    phone,
                    email,
                    message,
                    file
                })
            }).then(() => {
                resetForm();
                setReturnMessage({
                    visible: true,
                    type: 'ok',
                    value: 'message_sent'
                });
                setTimeout(() => {
                    setReturnMessage({
                        visible: false,
                        type: 'ok',
                        value: 'message_sent'
                    });
                }, 8000);
            }).catch(() => {
                setReturnMessage({
                    visible: true,
                    type: 'error',
                    value: 'message_failed'
                });
                setTimeout(() => {
                    setReturnMessage({
                        visible: false,
                        type: 'error',
                        value: 'message_failed'
                    });
                }, 8000);
            }).finally(() => {
                setIsLoading(false);
            });
        }
    };

    return (
        <FormContainer
            name="career"
            data-netlify="true"
            netlify-honeypot="bot-field"
            method='post'
            enctype='multipart/form-data'>
            <input name="bot-field"
                hidden />
            <Row>
                <Input ref={nameRef}
                    id="name"
                    value={name}
                    onChange={setName}
                    label={t('name')}
                    placeholder={t('name')}
                    required />
                <Input ref={surnameRef}
                    id="surname"
                    value={surname}
                    onChange={setSurname}
                    label={t('surname')}
                    placeholder={t('surname')}
                    required />
            </Row>
            <Row>
                <Input id="job"
                    value={job}
                    onChange={setJob}
                    label={t('job_wanted')}
                    placeholder={t('job')} />
                <Input id="phone"
                    value={phone}
                    onChange={setPhone}
                    label={t('phone')}
                    placeholder="01 23 45 67 89"
                    type="tel" />
            </Row>
            <Row>
                <Input ref={emailRef}
                    id="email"
                    value={email}
                    onChange={setEmail}
                    label={t('email')}
                    placeholder="label@email.com"
                    type="email"
                    required />
            </Row>
            <Row>
                <Textarea ref={messageRef}
                    id="message"
                    value={message}
                    onChange={setMessage}
                    label={t('message')}
                    placeholder="Message..."
                    required />
            </Row>
            <Row>
                <ButtonContainer>
                    <Label>
                        {t('file_contact_career')}
                        {'\n'}
                        Format pdf - max 800ko
                    </Label>
                    <ButtonFile onClick={handleButtonFileClick}>
                        {t('file_upload')}
                    </ButtonFile>
                    <FileName>
                        {file ? file.name : null}
                    </FileName>
                    <InputHidden id="file"
                        ref={fileRef}
                        onChange={handleSetFile}
                        label={t('file_contact_career')}
                        type="file" />
                </ButtonContainer>
            </Row>
            <Row center>
                {isLoading ? (
                    <Loader />
                ) : (
                    <Button onClick={handleSubmit}>
                        {t('send')}
                    </Button>
                )}
            </Row>
            <Row>
                <Message type={returnMessage.type}
                    visible={Boolean(returnMessage.visible)}>
                    {t(returnMessage.value)}
                </Message>
            </Row>
        </FormContainer>
    );
};

export default React.memo(ContactForm);
