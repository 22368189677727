import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import {
    PageTitle, MainText, BlockTitle
} from '../../components/Text';

import ContactForm from './../../components/career/ContactForm';
import Layout from './../../components/Layout';

const Container = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  width: 80%;


  ${({ theme }) => theme.media.tablet`
    width: calc(100% - 20px);
  `}
`;

const Row = styled.div`
  display: flex;

  ${({ theme }) => theme.media.tablet`
    flex-direction: column;
  `}
`;

const RowCenter = styled.div`
  display: flex;
  justify-content: center;
`;

const Block = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  padding-right: 20px;

  ${({ theme }) => theme.media.tablet`
    width: auto;
    padding-right: 0px;
  `}
`;

const BlockRight = styled(Block)`
  width: 40%;

  ${({ theme }) => theme.media.tablet`
    width: auto;
  `}
`;

const JobList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0 0 0 15px;
`;

const Job = styled.li`
  color: ${({ theme }) => theme.colors.mainBlue.hex};
  font-size: 18px;
  margin: 10px 0px;
`;

const Contact = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <Helmet>
                <title>
                    {t('career_meta_title')}
                </title>
                <meta name="description"
                    content={t('career_meta_description')} />
            </Helmet>
            <Container>
                <PageTitle>
                    {t('career')}
                </PageTitle>
                <Row>
                    <Block>
                        <BlockTitle>
                            {t('we_recruit')}
                        </BlockTitle>
                        <MainText>
                            {t('recruiting_text')}
                        </MainText>
                    </Block>
                    <BlockRight>
                        <BlockTitle>
                            {t('our_jobs')}
                        </BlockTitle>
                        <JobList>
                            <Job>Ouvrier polyvalent - H/F - CDD/CDI</Job>
                        </JobList>
                    </BlockRight>
                </Row>
                <RowCenter>
                    <ContactForm />
                </RowCenter>
            </Container>
        </Layout>
    );
};

export default React.memo(Contact);
